<template>
  <div>
    <div
      class="statistics-box"
      v-loading="isRequest"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
    >
      <div class="title">
        <div class="name">油耗状态数字统计</div>
        <div class="time">
          <el-button
            type="text"
            :class="{'ash': timeType != 'W' }"
            @click="handleChangeType('W')"
          >本周</el-button>
          <el-button
            type="text"
            :class="{'ash': timeType != 'M' }"
            @click="handleChangeType('M')"
          >本月</el-button>
          <el-button
            type="text"
            :class="{'ash': timeType != 'Y' }"
            @click="handleChangeType('Y')"
          >本年</el-button>
          <div style="margin-left: 20px; margin-right: 8px">选择时间</div>
          <div style="display: flex">
            <el-date-picker
              v-model="temporaryStart"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
            <div style="margin: 0 8px">至</div>
            <el-date-picker
              v-model="temporaryEnd"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="echarts-box">
        <div class="echarts">
          <div ref="lineBox" style="height: 410px; width: 100%"></div>
          <div class="num-box">
            <div>
              <span>{{ numData[0] }}升</span>
              <div>总油耗</div>
            </div>
            <div>
              <span>{{ numData[1] }}升</span>
              <div>作业油耗</div>
            </div>
            <div>
              <span>{{ numData[2] }}升</span>
              <div>怠速油耗</div>
            </div>
            <!-- <div>
              <span>{{ numData[3] }}升</span>
              <div>行走油耗</div>
            </div>-->
            <div>
              <span>{{ numData[4] }}升</span>
              <div>上报加油</div>
            </div>
            <div>
              <span>{{ numData[5] }}升</span>
              <div>异常油耗</div>
            </div>
          </div>
        </div>
        <div class="others">
          <div>
            <div>
              <span>{{ numData[6] }}升</span>
              <div>标准小时油耗</div>
            </div>
            <div>
              <span>{{ numData[7] }}升</span>
              <div>小时油耗</div>
            </div>
          </div>
        </div>
        <div class="notes">
          <div>
            <div v-for="(item) in oilList" :key="item.id" class="con">
              <Iconfont
                name="icon-circle"
                :size="18"
                :color="item.color"
                class="iconfont"
              ></Iconfont>
              <span>{{item.text}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 10px 0">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
        align="center"
      >
        <el-table-column label="序号" type="selection" width="55"></el-table-column>
        <el-table-column label="序号" type="index" width="55" align="center"></el-table-column>

        <el-table-column label="日期" width="120" prop="date" align="center"></el-table-column>

        <el-table-column
          prop="vehicleAbsName"
          label="车辆/车组"
          width="200"
          align="center"
        >
          <!-- <template slot-scope="scope">{{ scope.row.date }}</template> -->
        </el-table-column>

        <el-table-column
          prop="stateName"
          label="油耗状态"
          show-overflow-tooltip
          align="center"
        ></el-table-column>-->
        <el-table-column
          prop="oil"
          label="油耗/升"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productLineName"
          label="产品线"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="vehicleBrandName"
          label="品牌"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <span>{{scope.row.vehicleBrandName?scope.row.vehicleBrandName:"——"}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div
        class="loadingMore"
        v-if="securityList.length && securityList.length > 0"
        @click="DelayAssignList"
      >点击加载更多</div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { oilStatistics, oilTables } from "@/api/getData";
import { getWeek, getNextDays, getOilTimes } from "@/utils/getDate";
import dayjs from 'dayjs';
export default {
  name: "alarmStatistics",
  data() {
    return {
      searchLoading: false,
      startTime: "",
      endTime: "",
      dateStr: getWeek()[0],
      exportExcel: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      numData: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0
      },
      defaultStatis: [0, 0, 0, 0, 0],
      temporaryStart: "",
      temporaryEnd: "",
      timeType: "W",
      tableData: [],
      securityList: [],
      oilList: [
        // {
        //   id: 1,
        //   text: "总 油 耗 ：车辆累计消耗燃油量",
        //   color: "#D9D9D9"
        // },
        {
          id: 2,
          text: "作业油耗：车辆作业状态所消耗的燃油量",
          color: "#B7E77A"
        },
        {
          id: 3,
          text: "怠速油耗：车辆怠速状态所消耗的燃油量",
          color: "#FFF27D"
        },
        // {
        //   id: 4,
        //   text: "行走油耗：车辆行走状态所消耗的燃油量",
        //   color: "#FFBB33"
        // },
        { id: 5, text: "上报加油：加油记录及油量信息", color: "#BD85E8" },
        {
          id: 6,
          text: "异常油耗：车辆油量异常报警所消耗的燃油量",
          color: "#F87979"
        }
      ],
      echartsData: [],
      selectionList: [],
      vehicleIds: '',
      requestNum: 0,
      isRequest: 0,
      lineBox: null
    };
  },
  props: {
    option: {
      type: Array,
      default: function () {
        return [];
      }
    },
    vehicleId: {
      type: Array,
      default: function () {
        return [];
      }
    },
    tableDatas: {
      type: Array,
      default: function () {
        return [];
      }
    },
    groupIds: {
      type: Array,
      default: function () {
        return [];
      }
    },
  },
  watch: {
    vehicleId: {
      handler(val) {
        if (!this.isRequest) {
          this.requestNum = 1;
          this.getOilStatistics();
          this.getOilTables();
        }
      },
      // immediate: true,
      deep: true
    },
    groupIds: {
      handler(val) {
        if (!this.requestNum && !this.isRequest) {
          this.getOilStatistics();
          this.getOilTables();
        }
      },
      // immediate: true,
      deep: true
    }
  },
  created() {
    this.vehicleIds = this.vehicleId;
    this.tableData = this.tableDatas;
    this.echartsData = this.option;
    this.getWorkData();
  },
  mounted() {
    if (!this.option) {
      this.setLineCharts([], {});
    }

  },
  methods: {
    //获取油耗表格数据
    async getOilTables() {
      if (this.startTime && !this.endTime) {
        this.$message({
          message: '请选择结束时间！',
          type: 'warning'
        });
        return false;
      }
      if (!this.startTime && this.endTime) {
        this.$message({
          message: '请选择开始时间！',
          type: 'warning'
        });
        return false;
      }

      if (this.startTime && this.endTime && (new Date(this.startTime) > new Date(this.endTime))) {
        this.$message({
          message: '请选择正确的开始时间和结束时间！',
          type: 'warning'
        });
        return false;
      }
      if (this.startTime) {
        this.dateStr = this.startTime.substr(10);
      }
      this.securityList = [];
      this.tableData = [];
      this.vehicleIds = this.vehicleId;
      // this.isRequest += 1;
      await oilTables({
        vehicleIds: this.vehicleIds,
        viewType: this.timeType,
        dateStr: this.dateStr,
        startTime: this.startTime,
        endTime: this.endTime,
        groupIds: this.groupIds,
        exportExcel: this.exportExcel,
      }).then(res => {
        if (res && res.flag == 1) {
          const { obj } = res;
          this.securityList = obj;
          this.DelayAssignList();
        };
      });
      // this.isRequest -= 1;
      // this.getWorkData();
    },
    async getOilStatistics() {
      if (this.startTime && !this.endTime) {
        this.$message({
          message: '请选择结束时间！',
          type: 'warning',
          duration: 1000
        });
        return false;
      }
      if (!this.startTime && this.endTime) {
        this.$message({
          message: '请选择开始时间！',
          type: 'warning',
          duration: 1000
        });
        return false;
      }
      if (this.startTime && this.endTime && (new Date(this.startTime) > new Date(this.endTime))) {
        this.$message({
          message: '请选择正确的开始时间和结束时间！',
          type: 'warning'
        });
        return false;
      }
      if (this.startTime) {
        this.dateStr = this.startTime.substr(10);
      }
      this.vehicleIds = this.vehicleId;
      this.isRequest += 1;
      await oilStatistics({
        vehicleIds: this.vehicleIds,
        viewType: this.timeType,
        dateStr: this.dateStr,
        startTime: this.startTime,
        endTime: this.endTime,
        groupIds: this.groupIds,
        exportExcel: this.exportExcel,
      }).then(res => {
        if (res && res.flag == 1) {
          if (res && res.obj) {
            const { sumData, splitData } = res.obj;
            const echartsData = [];
            echartsData.push(sumData, splitData);
            this.echartsData = echartsData;
            this.$message({
              type: "success",
              duration: 2000,
              message: "油耗统计获取成功",
            });
          }
        };
      });
      this.isRequest -= 1;
      this.requestNum = 0;
      this.getWorkData();
    },
    async getWorkData() {
      let { numData, xData, series } = await this.processPostData();
      this.numData = numData;
      this.setLineCharts(xData, series);
    },
    //设置柱状图数据
    setLineCharts(xData, series) {
      let op = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            let str = '';
            str += params[0].axisValue + '<br>';
            params.map(val => {
              val.value += '';
              let time = val.value.split('.');
              if (time.length > 1) {
                time[1] = time[1].substring(0, 2);
              };
              str += val.marker + val.seriesName + '：' + `${ val.data }升 ` + '<br>';
            });
            return str;
          }
        },
        legend: {
          bottom: -10,
          left: "center",
          itemWidth: 10, //设置宽度
          itemHeight: 10, //设置高度
          itemGap: 18,
          icon: "roundRect",
          textStyle: {
            fontSize: '14px',
            fontWeight: 700
          },
          // "行走油耗",
          data: ["作业油耗", "怠速油耗", "上报加油", "异常油耗"],
          padding: 10
        },
        grid: {
          left: "3%",
          top: "12%",
          right: "4%",
          bottom: "16%",
          containLabel: true,
        },
        xAxis: [
          {
            bottom: "5%",
            type: "category",
            name: "日期",
            nameGap: 5,
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#cccccc",
              },
            },
            axisLabel: {
              show: true,
              //   interval: 0,
              textStyle: {
                color: "#333333", //更改坐标轴文字颜色
              },
            },
            splitNumber: 2,
            maxInterval: 25,
            minInterval: 25,
            data: xData,
          },
        ],
        yAxis: [
          {
            name: "油耗/升",
            type: "value",
            axisLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#cccccc",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333", //更改坐标轴文字颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#EFEFEF",
              },
            },
          },
        ],
        dataZoom: [
          {
            type: "slider",
            show: true,
            handleSize: 5,
            height: 12,
            startValue: 0,
            endValue: 6,
            filterMode: "filter",
          },
          {
            type: "inside",
            xAxisIndex: [0],
            startValue: 0,
            endValue: 6,
          },
        ],
        series: [
          {
            name: "作业油耗",
            stack: 'Ad',
            data: series[1] || this.defaultStatis,
            type: 'bar',
            itemStyle: {
              normal: {
                color: '#B7E77A', //折点颜色
              }
            },
          },
          {
            name: "怠速油耗",
            data: series[2] || this.defaultStatis,
            stack: 'Ad',
            type: 'bar',
            itemStyle: {
              normal: {
                color: '#FFF27D', //折点颜色
              }
            },
          },
          // {
          //   name: "行走油耗",
          //   stack: 'Ad',
          //   data: series[3] || this.defaultStatis,
          //   type: 'bar',
          //   itemStyle: {
          //     normal: {
          //       color: '#FFBB33', //折点颜色
          //     }
          //   },
          // },
          {
            name: "上报加油",
            stack: 'Ad',
            data: series[4] || this.defaultStatis,
            type: 'bar',
            itemStyle: {
              normal: {
                color: '#BD85E8', //折点颜色
              }
            },
          },
          {
            name: "异常油耗",
            stack: 'Ad',
            data: series[5] || this.defaultStatis,
            type: 'bar',
            itemStyle: {
              normal: {
                color: '#F87979', //折点颜色
              }
            },
          }
        ],
      };
      this.lineBox ? this.lineBox.clear() : this.lineBox = echarts.init(this.$refs.lineBox, null, {});
      this.lineBox.setOption(op);
    },

    handleSelectionChange(val) {
      this.selectionList = val;
    },
    tableRowClassName({ row, rowIndex }) {
      // "check-row"
      return "";
    },

    //  周月年切换
    handleChangeType(val) {
      this.dateStr = getOilTimes(val);
      this.timeType = val;
      this.startTime = "";
      this.endTime = "";
      this.getOilStatistics();
      this.getOilTables();
    },
    //  日期选择
    hadnleChangeTime(val) {
      if (this.temporaryStart && !this.temporaryEnd) {
        this.$message({
          message: '请选择结束时间！',
          type: 'warning'
        });
        return false;
      } else if (!this.temporaryStart && this.temporaryEnd) {
        this.$message({
          message: '请选择开始时间！',
          type: 'warning'
        });
        return false;
      } else if (this.temporaryStart && this.temporaryEnd && (new Date(this.temporaryStart) > new Date(this.temporaryEnd))) {
        this.$message({
          message: '请选择正确的开始时间和结束时间！',
          type: 'warning'
        });
        return false;
      } else {
        this.startTime = this.temporaryStart;
        this.dateStr = this.startTime.substr(10);
        const endTime = this.temporaryEnd;
        this.endTime = getNextDays(endTime);
        this.timeType = "D";
        this.getOilStatistics();
        this.getOilTables();
      }
    },
    processPostData() {
      let numData = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0
      };
      let xData = [];
      let series = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
      };
      if (this.echartsData && this.echartsData[0]) {
        const sumDatas = this.echartsData[0];
        numData[0] = sumDatas.sumDepleteOil;
        numData[1] = sumDatas.workingOil;
        numData[2] = sumDatas.idlingOil;
        numData[3] = sumDatas.runningOil;
        numData[4] = sumDatas.sumIncreaseOil;
        numData[5] = sumDatas.abnormalOil;
        numData[6] = sumDatas.standardHoursOil;
        numData[7] = sumDatas.hoursOil;
      };
      for (const key in this.echartsData[1]) {
        xData.push(this.echartsData[1][key].dateStr);
        // if (this.timeType == "Y") {
        //   xData.push(this.echartsData[1][key].dateStr.substr(0, 7));
        // } else {
        //   xData.push(this.echartsData[1][key].dateStr);
        // }
        if (this.echartsData[1][key]) {
          if (this.echartsData[1][key].sumDepleteOil) {
            series[0].push(this.echartsData[1][key].sumDepleteOil);
          } else {
            series[0].push(0);
          }
          if (this.echartsData[1][key].workingOil) {
            series[1].push(this.echartsData[1][key].workingOil);
          } else {
            series[1].push(0);
          }
          if (this.echartsData[1][key].idlingOil) {
            series[2].push(this.echartsData[1][key].idlingOil);
          } else {
            series[2].push(0);
          }
          if (this.echartsData[1][key].runningOil) {
            series[3].push(this.echartsData[1][key].runningOil);
          } else {
            series[3].push(0);
          }
          if (this.echartsData[1][key].sumIncreaseOil) {
            series[4].push(this.echartsData[1][key].sumIncreaseOil);
          } else {
            series[4].push(0);
          }
          if (this.echartsData[1][key].abnormalOil) {
            series[5].push(this.echartsData[1][key].abnormalOil);
          } else {
            series[5].push(0);
          }
        } else {
          series[0].push(0);
          series[1].push(0);
          series[2].push(0);
          series[3].push(0);
          series[4].push(0);
          series[5].push(0);
        }
      };

      return { numData, xData, series };
    },
    DelayAssignList() {
      if (this.securityList.length > 0) {
        this.tableData.push(...this.securityList.splice(0, 10));
        // this.listTime = setTimeout(()=>{
        //   clearTimeout(this.listTime);
        //   this.listTime = null;
        //     this.DelayAssignList();
        // }, 1200)
      } else {
        // this.$message.error('列表加载完成!');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.statistics-box {
  background-color: #fff;
  padding-bottom: 38px;
  overflow: hidden;
  > .title {
    display: flex;
    justify-content: space-between;
    margin: 0 24px;
    line-height: 48px;
    border-bottom: 1px dashed #cccccc;
    > .name {
      position: relative;
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #666666;
    }
    > .name::after {
      content: '';
      position: absolute;
      left: 0px;
      top: 15px;
      height: 16px;
      width: 4px;
      background-color: rgba(66, 120, 201, 1);
    }
    > .time {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      color: #666666;
      > .ash {
        color: #666666;
      }
    }
  }
  > .echarts-box {
    min-width: 1000px;
    display: flex;
    margin-top: 16px;
    > .echarts {
      flex: 1;
      > .num-box {
        display: flex;
        justify-content: space-between;
        width: 800px;
        margin: auto;
        margin-top: 20px;
        > div {
          width: 120px !important;
          text-align: center;
          > div {
            font-size: 12px;
            color: #666666;
            margin-top: 4px;
          }
          > span {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
    > .others {
      width: 14%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        > div {
          text-align: center;
          &:last-child {
            margin-top: 20px;
          }
          > span {
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            font-family: PingFang SC-Bold, PingFang SC;
          }
          > div {
            font-size: 12px;
            color: #666666;
            margin-top: 4px;
          }
        }
      }
    }
    > .notes {
      width: 28%;
      margin-right: 8px;
      margin-left: 4px;
      display: flex;
      align-items: center;
      > div {
        .con {
          margin: 20px 0;
          .iconfont {
            margin-right: 10px;
          }
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}
.check-row {
  background-color: rgba(220, 232, 252, 1);
}
.loadingMore {
  padding: 12px;
  text-align: center;
  color: #409eff;
  cursor: pointer;
  background-color: #fff;
}
.loadingMore:hover {
  color: #409eff;
}
::v-deep .el-input--small .el-input__inner {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}
</style>

<style>
.myBox {
  min-width: 300px !important;
  min-height: 10px !important;
  border: 1px solid #ccc;
}
</style>