<template>
  <div style="margin-top: 10px; height: calc(100% - 10px)">
    <RouterKit :contentList="contentList"
      title="油耗管理"
      iconName="icon-youhaoguanli"
      @change="handleChangePath">
      <template v-slot:form>
        <Form :groupCount="groupCountList"
          ref="formBox"
          @change="hadnleChangeForm"
          @Inquire="handleInquire"
          @export="handleExport"
          :isExport="componentName=='statistics'"
          style="margin-bottom: 10px" />
      </template>
      <template v-slot:route>
        <keep-alive>
          <component :is="componentName"
            :vehicleId="vehicleId"
            :groupIds="groupIds"
            ref="componentRef"
            :option="option"
            :tableDatas="tableDatas" />
        </keep-alive>
      </template>
    </RouterKit>
  </div>
</template>

<script>
import RouterKit from "@/components/RouterKit/index";
import Form from "@/components/RouterKit/components/Form";
import NoPermission from "@/components/RouterKit/components/NoPermission.vue";
import { groupCount, groupCountForAlarm } from "@/api/getData";
import statistics from "@/views/oilManagement/components/statistics";
import analyze from "@/views/oilManagement/components/analyze";
import { oilStatistics, oilTables, oilDownload } from "@/api/getData";
import { getWeek } from "@/utils/getDate";
export default {
  name: 'oilManagement',
  components: {
    RouterKit,
    Form,
    statistics,
    analyze,
    NoPermission
  },
  data() {
    return {
      groupCountList: [],
      tableDatas: [],
      componentName: "statistics",
      contentList: [
        {
          path: "statistics",
          title: "数据统计",
          iconName: "icon-shujutongji",
        },
        {
          path: "analyze",
          title: "数据分析",
          iconName: "icon-shujufenxi",
        },
        {
          path: "NoPermission",
          title: "数据管理",
          iconName: "icon-shujuguanli",
        },
      ],
      getData: {
        vehicleIds: [],
        viewType: "W",
        startTime: "",
        endTime: "",
        groupIds: [],
        dateStr: "",
        exportExcel: false,
        flag: true
      },
      vehicleId: [],
      groupIds: [],
      option: [],
    };
  },
  activated() {
    this.getData.dateStr = getWeek()[0];
    this.init().then(() => {
      if (this.$route.params.vehicle) {
        this.vehicleId = [this.$route.params.vehicle.V];
        this.getData.vehicleIds = [this.$route.params.vehicle.V];
      }
    });
    // this.getOilStatistics();
  },
  mounted() {
  },
  methods: {
    async init() {
      // title   分类名称
      // list     分类数据数组
      // backName  返回字段名称
      let groupCountList = [];
      let group = await groupCount();

      if (group.flag == 1) {
        if (group.obj.stateCountData) {
          let num = 0;
          // let vehicleIdss = "";
          group.obj.stateCountData.map((val) => {
            // if (val.vehicleIds) {
            //   vehicleIdss += val.vehicleIds + ",";
            // }
            num += val.sumResult;
          });
          // vehicleIdss = vehicleIdss.substr(0, vehicleIdss.length - 1);
          // if (vehicleIdss != "") {
          //   this.vehicleId = vehicleIdss.split(',');
          // }
          // this.componentName = "statistics";
          group.obj.stateCountData.unshift({
            label: "全部",
            labelValue: "",
            sumResult: num,
          });
          groupCountList.push({
            title: "设备状态",
            list: group.obj.stateCountData,
            backName: "stateCount", //选择后回调字段
          });
        }
        if (group.obj.productLineCountData) {
          let num = 0;
          group.obj.productLineCountData.map((val) => {
            num += val.sumResult;
          });
          group.obj.productLineCountData.unshift({
            label: "全部",
            labelValue: "",
            sumResult: num,
          });
          groupCountList.push({
            title: "产品线",
            list: group.obj.productLineCountData,
            backName: "product", //选择后回调字段
          });
        }
        if (group.obj.brandCountData) {
          let num = 0;
          group.obj.brandCountData.map((val) => {
            num += val.sumResult;
          });
          group.obj.brandCountData.unshift({
            label: "全部",
            labelValue: "",
            sumResult: num,
          });
          groupCountList.push({
            title: "品牌分类",
            list: group.obj.brandCountData,
            backName: "brandCount", //选择后回调字段
          });
        }
      }
      this.groupCountList = groupCountList;
    },
    // 查询
    handleInquire(val) {
      // this.getData.vehicleIds = val.vehicleIds ? val.vehicleIds : [];
      // this.vehicleId = val.vehicleIds ? val.vehicleIds : [];
      // this.getOilTables();
    },
    //获取油耗统计数据
    async getOilStatistics() {
      await this.getOilTables();
      oilStatistics(this.getData).then(res => {
        if (res && res.flag == 1) {
          if (res && res.obj) {
            const { splitData, sumData } = res.obj;
            this.option.push(sumData, splitData);
            this.$message({
              type: "success",
              duration: 1000,
              message: "油耗统计获取成功",
            });
          }
        };
        this.componentName = "statistics";
      });
    },

    //获取油耗表格数据
    async getOilTables() {
      await oilTables(this.getData).then(res => {
        if (res && res.flag == 1) {
          const { obj } = res;
          this.tableDatas = obj ? obj : [];
        };
      });
    },
    handleChangePath(val) {
      this.componentName = val;
      // this.$refs.formBox.handleInit();
    },
    hadnleChangeForm(val) {
      this.groupIds = val.groupIds ? val.groupIds.split(',') : [];
      this.vehicleId = val.vehicleIds ? val.vehicleIds.split(',') : [];
    },

    //下载油耗
    async handleExport() {
      if (this.$refs.componentRef.selectionList) {
        // if(this.$refs.componentRef.selectionList.length == 0){
        //   this.$message({
        //     message: '请选择需要导出的数据',
        //     type: 'warning'
        //   });
        //   return false;
        // }else {
        await oilDownload({
          vehicleIds: this.$refs.componentRef.vehicleIds,
          flag: false,
          dateStr: this.$refs.componentRef.dateStr,
          viewType: this.$refs.componentRef.timeType,
          startTime: this.$refs.componentRef.startTime,
          endTime: this.$refs.componentRef.endTime,
          groupIds: this.$refs.componentRef.groupIds,
          exportExcel: true,
          responseType: 'blob'
        }).then(res => {
          if (res.flag == 1) {
          };
        });
        // }
      };
    }
  },
};
</script>

<style>
.myBox {
  min-width: 300px !important;
  min-height: 10px !important;
  border: 1px solid #ccc;
}
</style>