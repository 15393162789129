<template>
  <div>
    <div
      class="statistics-box"
      v-loading="searchLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
    >
      <div class="title">
        <div class="name">油耗状态占比分析</div>
        <div class="time">
          <el-button
            type="text"
            :class="{ ash: pieTimeType != 'W' }"
            @click="handleChangeType('W', 'setPieEcharts')"
          >本周</el-button>
          <el-button
            type="text"
            :class="{ ash: pieTimeType != 'M' }"
            @click="handleChangeType('M', 'setPieEcharts')"
          >本月</el-button>
          <el-button
            type="text"
            :class="{ ash: pieTimeType != 'Y' }"
            @click="handleChangeType('Y', 'setPieEcharts')"
          >本年</el-button>
          <div style="margin-left: 20px; margin-right: 8px">选择时间</div>
          <div style="display: flex">
            <el-date-picker
              v-model="pieStartTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setPieEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
            <div style="margin: 0 8px">至</div>
            <el-date-picker
              v-model="pieEndTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setPieEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="echarts-box">
        <div class="echarts">
          <div ref="lineBox" style="height: 410px; width: 100%"></div>
        </div>
        <div class="notes">
          <div v-for="(item) in oilList" :key="item.id" class="con">
            <Iconfont
              name="icon-circle"
              :size="18"
              :color="item.color"
              class="iconfont"
            ></Iconfont>
            <span>{{item.text}}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="statistics-box"
      style="margin: 10px 0"
      v-loading="searchLineLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
    >
      <div class="title">
        <div class="name">油耗状态走势分析</div>
        <div class="time">
          <el-button
            type="text"
            :class="{ ash: lineTimeType != 'W' }"
            @click="handleChangeType('W','setLineEcharts')"
          >本周</el-button>
          <el-button
            type="text"
            :class="{ ash: lineTimeType != 'M' }"
            @click="handleChangeType('M','setLineEcharts')"
          >本月</el-button>
          <el-button
            type="text"
            :class="{ ash: lineTimeType != 'Y' }"
            @click="handleChangeType('Y','setLineEcharts')"
          >本年</el-button>
          <div style="margin-left: 20px; margin-right: 8px">选择时间</div>
          <div style="display: flex">
            <el-date-picker
              v-model="lineStartTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setLineEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
            <div style="margin: 0 8px">至</div>
            <el-date-picker
              v-model="lineEndTime"
              type="date"
              style="width: 130px"
              :picker-options="pickerOptions"
              @change="hadnleChangeTime('setLineEcharts')"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="echarts-box">
        <div class="echarts">
          <div ref="statisticsLine" style="height: 410px; width: 100%"></div>
        </div>
        <div class="notes">
          <div v-for="(item) in oilList" :key="item.id" class="con">
            <Iconfont
              name="icon-circle"
              :size="18"
              :color="item.color"
              class="iconfont"
            ></Iconfont>
            <span>{{item.text}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { oilStatistics } from "@/api/getData";
import { getWeek, getNextDays, getOilTimes } from "@/utils/getDate";
import dayjs from 'dayjs';

export default {
  name: "alarmAnalyze",
  data() {
    return {
      timeout: "",
      req: "",
      searchLoading: 0,
      searchLineLoading: false,
      startTime: "",
      endTime: "",
      pieDateStr: getWeek()[0],
      lineDateStr: getWeek()[0],
      exportExcel: false,
      pieStartTime: "",
      pieEndTime: "",
      lineStartTime: "",
      lineEndTime: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pieTimeType: "W",
      lineTimeType: "W",
      tableData: [],
      defaultStatis: [0, 0, 0, 0, 0, 0, 0],
      oilList: [
        // {
        //   id: 1,
        //   text: "总 油 耗 ：车辆累计消耗燃油量",
        //   color: "#D9D9D9"
        // },
        {
          id: 2,
          text: "作业油耗：车辆作业状态所消耗的燃油量",
          color: "#B7E77A"
        },
        {
          id: 3,
          text: "怠速油耗：车辆怠速状态所消耗的燃油量",
          color: "#FFF27D"
        },
        // {
        //   id: 4,
        //   text: "行走油耗：车辆行走状态所消耗的燃油量",
        //   color: "#FFBB33"
        // },
        { id: 5, text: "上报加油：加油记录及油量信息", color: "#BD85E8" },
        {
          id: 6,
          text: "异常油耗：车辆油量异常报警所消耗的燃油量",
          color: "#F87979"
        }
      ],
      echartsData: [],
      vehicleIds: [],
      requestNum: 0
    };
  },
  props: {
    option: {
      type: Array,
      default: []
    },
    vehicleId: {
      type: Array,
      default: []
    },
    groupIds: {
      type: Array,
      default: []
    },
  },
  watch: {
    vehicleId: {
      handler(val) {
        if (!this.searchLoading) {
          this.requestNum = 1;
          this.getOilAnanysis('setPieEcharts');
          this.getOilAnanysis('setLineEcharts');
        }
      },
      // immediate: true,
      deep: true,
    },
    groupIds: {
      handler(val) {
        if (!this.requestNum && !this.searchLoading) {
          this.getOilAnanysis('setPieEcharts');
          this.getOilAnanysis('setLineEcharts');
        }
      },
      // immediate: true,
      deep: true
    }
  },
  created() {
    this.vehicleIds = this.vehicleId;
    this.echartsData = this.option;
    this.getWorkData();
  },
  mounted() {
    if (!this.option) {
      this.setPieEcharts([]);
      this.setLineEcharts({}, [], {});
    }
  },
  methods: {
    //饼图
    async getOilAnanysis(type) {
      if (this.startTime && !this.endTime) {
        this.$message({
          message: '请选择结束时间！',
          type: 'warning'
        });
        return false;
      }
      if (!this.startTime && this.endTime) {
        this.$message({
          message: '请选择开始时间！',
          type: 'warning'
        });
        return false;
      }
      if (this.startTime && this.endTime && (new Date(this.startTime) > new Date(this.endTime))) {
        this.$message({
          message: '请选择正确的开始时间和结束时间！',
          type: 'warning'
        });
        return false;
      }
      this.vehicleIds = this.vehicleId;
      this.searchLoading += 1;
      await oilStatistics({
        vehicleIds: this.vehicleIds,
        viewType: type == 'setPieEcharts' ? this.pieTimeType : this.lineTimeType,
        dateStr: type == 'setPieEcharts' ? this.pieDateStr : this.lineDateStr,
        startTime: this.startTime,
        endTime: this.endTime,
        groupIds: this.groupIds,
        exportExcel: this.exportExcel,
        flag: true
      }).then(res => {
        if (res && res.flag == 1 && res.obj) {
          const { splitData, sumData } = res.obj;
          if (type == "setLineEcharts") {
            this.searchLineLoading = false;
            this.echartsData[1] = splitData;
            this.$message({
              type: "success",
              duration: 2000,
              message: "油耗走势获取成功",
            });
          }
          if (type == "setPieEcharts") {
            this.echartsData[0] = sumData;
            this.$message({
              type: "success",
              duration: 2000,
              message: "油耗占比获取成功",
            });


          }
        };
      });
      this.searchLoading -= 1;
      this.getWorkData(type);
    },
    //折线图

    async getWorkData(type) {
      let { numData, xData, series } = await this.processPostData();
      console.log("type", type);

      if (type) {
        this[type](numData, xData, series);

      } else {
        this.setPieEcharts(numData, xData, series);
        this.setLineEcharts(numData, xData, series);

      }
    },
    setPieEcharts(numData) {
      let oData = [
        // {
        //   value: numData[0],
        //   name: "总油耗",
        //   label: {
        //     formatter: [
        //       "{b}",
        //       "{he|}",
        //       "{hr|}",
        //       "{he|}",
        //       "{valueHead|{d}%}",
        //     ].join("\n"),
        //     //   {val.name}</div><div>${val.percent}
        //     rich: {
        //       title: {
        //         align: "center",
        //       },
        //       hr: {
        //         borderColor: "#D9D9D9",
        //         width: "100%",
        //         borderWidth: 0.5,
        //         height: 0,
        //       },
        //       he: {
        //         height: 2,
        //       },
        //       valueHead: {
        //         color: "#333",
        //         align: "center",
        //       },
        //     },
        //   },
        // },
        {
          value: numData[1] || 0,
          name: "作业油耗",
          label: {
            formatter: [
              "{b}",
              "{he|}",
              "{hr|}",
              "{he|}",
              "{valueHead|{d}%}",
            ].join("\n"),
            //   {val.name}</div><div>${val.percent}
            rich: {
              title: {
                align: "center",
              },
              hr: {
                borderColor: "#B7E77A",
                width: "100%",
                borderWidth: 0.5,
                height: 0,
              },
              he: {
                height: 2,
              },
              valueHead: {
                color: "#333",
                align: "center",
              },
            },
          },
          itemStyle: {
            color: '#B7E77A'
          }
        },

        {
          value: numData[2] || 0,
          name: "怠速油耗",
          label: {
            formatter: [
              "{b}",
              "{he|}",
              "{hr|}",
              "{he|}",
              "{valueHead|{d}%}",
            ].join("\n"),
            //   {val.name}</div><div>${val.percent}
            rich: {
              title: {
                align: "center",
              },
              hr: {
                borderColor: "#FFF27D",
                width: "100%",
                borderWidth: 0.5,
                height: 0,
              },
              he: {
                height: 2,
              },
              valueHead: {
                color: "#333",
                align: "center",
              },
            },
          },
          itemStyle: {
            color: '#FFF27D'
          }
        },
        // {
        //   value: numData[3] || 0,
        //   name: "行走油耗",
        //   label: {
        //     formatter: [
        //       "{b}",
        //       "{he|}",
        //       "{hr|}",
        //       "{he|}",
        //       "{valueHead|{d}%}",
        //     ].join("\n"),
        //     //   {val.name}</div><div>${val.percent}
        //     rich: {
        //       title: {
        //         align: "center",
        //       },
        //       hr: {
        //         borderColor: "#FFBB33",
        //         width: "100%",
        //         borderWidth: 0.5,
        //         height: 0,
        //       },
        //       he: {
        //         height: 2,
        //       },
        //       valueHead: {
        //         color: "#333",
        //         align: "center",
        //       },
        //     },
        //   },
        //   itemStyle: {
        //     color: '#FFBB33'
        //   }
        // },
        {
          value: numData[4] || 0,
          name: "上报加油",
          label: {
            formatter: [
              "{b}",
              "{he|}",
              "{hr|}",
              "{he|}",
              "{valueHead|{d}%}",
            ].join("\n"),
            //   {val.name}</div><div>${val.percent}
            rich: {
              title: {
                align: "center",
              },
              hr: {
                borderColor: "#BD85E8",
                width: "100%",
                borderWidth: 0.5,
                height: 0,
              },
              he: {
                height: 2,
              },
              valueHead: {
                color: "#333",
                align: "center",
              },
            },
          },
          itemStyle: {
            color: '#BD85E8'
          }
        },
        {
          value: numData[5] || 0,
          name: "异常油耗",
          label: {
            formatter: [
              "{b}",
              "{he|}",
              "{hr|}",
              "{he|}",
              "{valueHead|{d}%}",
            ].join("\n"),
            //   {val.name}</div><div>${val.percent}
            rich: {
              title: {
                align: "center",
              },
              hr: {
                borderColor: "#F87979",
                width: "100%",
                borderWidth: 0.5,
                height: 0,
              },
              he: {
                height: 2,
              },
              valueHead: {
                color: "#333",
                align: "center",
              },
            },
          },
          itemStyle: {
            color: '#F87979'
          }
        }
      ];
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c}升 ({d}%)",
        },
        // '#D9D9D9', 
        // color: ['#B7E77A', '#FFF27D', '#FFBB33', '#BD85E8', '#F87979'],
        legend: {
          itemWidth: 10,
          itemHeight: 10,
          icon: "roundRect",
          bottom: 4,
          left: "center",
          textStyle: {
            fontSize: '14px',
            fontWeight: 700
          },
          // "行走油耗",
          data: ["作业油耗", "怠速油耗", "上报加油", "异常油耗"],
        },
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "45%"],
            selectedMode: "single",
            data: oData.filter(val => val.value),
            label: {
              distanceToLabelLine: 0,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      let lineBox = echarts.init(this.$refs.lineBox, null, {});
      lineBox.setOption(option);
    },
    setLineEcharts(numData, xData, series) {
      let option = {
        grid: {
          left: "3%",
          bottom: '20%',
          top: '28px',
          containLabel: true
        },
        legend: {
          bottom: -10,
          left: "center",
          itemWidth: 10, //设置宽度
          itemHeight: 10, //设置高度
          itemGap: 18,
          icon: "roundRect",
          textStyle: {
            fontSize: '14px',
            fontWeight: 700
          },
          margin: [0, 0, -10, 0],
          padding: 10
        },
        xAxis: {
          boundaryGap: false,
          name: '日期',
          type: 'category',
          data: xData,
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "#cccccc"
            }
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#333333', //更改坐标轴文字颜色
            }
          },
          nameTextStyle: {
            // fontSize: 8      //更改坐标轴文字大小
          },
        },
        yAxis: {
          type: 'value',
          name: '油耗/升',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#333333',  //更改坐标轴文字颜色
            }
          },
          nameTextStyle: {
            // fontSize: 8      //更改坐标轴文字大小
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: '#cccccc'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#EFEFEF"
            }
          }

        },
        series: [
          {
            name: "作业油耗",
            data: series[1] || this.defaultStatis,
            type: 'line',
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: '#B7E77A', //折点颜色
                lineStyle: {
                  color: '#B7E77A' //折线颜色
                }
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(183, 231, 122, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(255, 230, 97, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
          },
          {
            name: "怠速油耗",
            data: series[2] || this.defaultStatis,
            type: 'line',
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: '#FFF27D', //折点颜色
                lineStyle: {
                  color: '#FFF27D' //折线颜色
                }
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(255, 242, 125, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
          },
          // {
          //   name: "行走油耗",
          //   data: series[3] || this.defaultStatis,
          //   type: 'line',
          //   smooth: 0.6,
          //   itemStyle: {
          //     normal: {
          //       color: '#FFBB33', //折点颜色
          //       lineStyle: {
          //         color: '#FFBB33' //折线颜色
          //       }
          //     }
          //   },
          //   areaStyle: {
          //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //       offset: 0,
          //       color: 'rgba(255, 187, 51, 1)'
          //     }, {
          //       offset: 1,
          //       color: 'rgba(255, 255, 255, 0.3)'
          //     }])
          //   },
          // },
          {
            name: "上报加油",
            data: series[4] || this.defaultStatis,
            type: 'line',
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: '#BD85E8', //折点颜色
                lineStyle: {
                  color: '#BD85E8' //折线颜色
                }
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(189, 133, 232, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
          },
          {
            name: "异常油耗",
            data: series[5] || this.defaultStatis,
            type: 'line',
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: '#F87979', //折点颜色
                lineStyle: {
                  color: '#F87979' //折线颜色
                }
              }
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(248, 121, 121, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.3)'
              }])
            },
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            label: {}
          },
          formatter: function (params) {
            let str = '';
            str += params[0].axisValue + '<br>';
            params.map(val => {
              val.value += '';
              let time = val.value.split('.');
              if (time.length > 1) {
                time[1] = time[1].substring(0, 2);
              };
              str += val.marker + val.seriesName + '：' + `${ val.data }升 ` + '<br>';
            });
            return str;
          }
        },

      };
      let statisticsLine = echarts.init(this.$refs.statisticsLine, null, {});
      statisticsLine.setOption(option);
    },
    handleSelectionChange(val) {
    },
    tableRowClassName({ row, rowIndex }) {
      return "";
    },
    handleChangeType(val, type) {
      type == 'setPieEcharts' ? this.pieDateStr = getOilTimes(val) : this.lineDateStr = getOilTimes(val);
      this.startTime = "";
      this.endTime = "";
      type == 'setPieEcharts' ? this.pieTimeType = val : this.lineTimeType = val;
      this.getOilAnanysis(type);
    },
    hadnleChangeTime(val) {
      let startTime = "";
      let endTime = "";
      if (val == 'setPieEcharts') {
        startTime = this.pieStartTime;
        endTime = this.pieEndTime;
        this.pieTimeType = "D";
      } else {
        startTime = this.lineStartTime;
        endTime = this.lineEndTime;
        this.lineTimeType = "D";
      }
      if (startTime && !endTime) {
        this.$message({
          message: "请选择结束时间！",
          type: "warning",
          duration: 1000
        });
        return false;
      } else if (!startTime && endTime) {
        this.$message({
          message: "请选择开始时间！",
          type: "warning",
          duration: 1000
        });
        return false;
      } else if (
        startTime &&
        endTime &&
        new Date(startTime) > new Date(endTime)
      ) {
        this.$message({
          message: "请选择正确的开始时间和结束时间！",
          type: "warning",
        });
        return false;
      } else {
        this.startTime = startTime;
        this.endTime = getNextDays(endTime);
        this.pieDateStr = "";
        this.lineDateStr = "";
        this.getOilAnanysis(val).then(() => {

        });
      }
    },
    processPostData() {
      let numData = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      };
      let xData = [];
      let series = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
      };
      if (this.echartsData && this.echartsData[0]) {
        const sumDatas = this.echartsData[0];
        numData[0] = sumDatas.sumDepleteOil;
        numData[1] = sumDatas.workingOil;
        numData[2] = sumDatas.idlingOil;
        numData[3] = sumDatas.runningOil;
        numData[4] = sumDatas.sumIncreaseOil;
        numData[5] = sumDatas.abnormalOil;
      };
      for (const key in this.echartsData[1]) {
        if (this.lineTimeType == "Y") {
          xData.push(this.echartsData[1][key].dateStr.substr(0, 7));
        } else {
          xData.push(this.echartsData[1][key].dateStr);
        }
        if (this.echartsData[1][key]) {
          if (this.echartsData[1][key].sumDepleteOil) {
            series[0].push(this.echartsData[1][key].sumDepleteOil);
          } else {
            series[0].push(0);
          }
          if (this.echartsData[1][key].workingOil) {
            series[1].push(this.echartsData[1][key].workingOil);
          } else {
            series[1].push(0);
          }
          if (this.echartsData[1][key].idlingOil) {
            series[2].push(this.echartsData[1][key].idlingOil);
          } else {
            series[2].push(0);
          }
          if (this.echartsData[1][key].runningOil) {
            series[3].push(this.echartsData[1][key].runningOil);
          } else {
            series[3].push(0);
          }
          if (this.echartsData[1][key].sumIncreaseOil) {
            series[4].push(this.echartsData[1][key].sumIncreaseOil);
          } else {
            series[4].push(0);
          }
          if (this.echartsData[1][key].abnormalOil) {
            series[5].push(this.echartsData[1][key].abnormalOil);
          } else {
            series[5].push(0);
          }
        } else {
          series[0].push(0);
          series[1].push(0);
          series[2].push(0);
          series[3].push(0);
          series[4].push(0);
          series[5].push(0);
        }
      };
      return { numData, xData, series };
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-box {
  background-color: #fff;
  padding-bottom: 38px;
  overflow: hidden;
  > .title {
    display: flex;
    justify-content: space-between;
    margin: 0 24px;
    line-height: 48px;
    border-bottom: 1px dashed #cccccc;
    > .name {
      position: relative;
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #666666;
    }
    > .name::after {
      content: '';
      position: absolute;
      left: 0px;
      top: 15px;
      height: 16px;
      width: 4px;
      background-color: rgba(66, 120, 201, 1);
    }
    > .time {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      color: #666666;
      > .ash {
        color: #666666;
      }
    }
  }
  > .echarts-box {
    overflow: hidden;
    display: flex;
    margin-top: 16px;
    min-width: 1000px;
    > .echarts {
      flex: 1;
      > .num-box {
        display: flex;
        justify-content: space-between;
        width: 350px;
        margin: auto;
        > div {
          text-align: center;
          > div {
            font-size: 12px;
            color: #666666;
            margin-top: 4px;
          }
          > span {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
    > .notes {
      width: 28%;
      margin-right: 8px;
      .con {
        margin: 20px 0;
        .iconfont {
          margin-right: 10px;
        }
        span {
          font-size: 16px;
        }
      }
    }
  }
}
.check-row {
  background-color: rgba(220, 232, 252, 1);
}
::v-deep .el-input--small .el-input__inner {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}
</style>
<style>
.myBox {
  min-width: 300px !important;
  min-height: 10px !important;
  border: 1px solid #ccc;
}
</style>